import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				OceanQuest Yachting
			</title>
			<meta name={"description"} content={"Наш різноманітний флот ідеально підходить для будь-якої прогулянки. Вирушайте з нами на унікальну втечу на хвилях."} />
			<meta property={"og:title"} content={"OceanQuest Yachting"} />
			<meta property={"og:description"} content={"Наш різноманітний флот ідеально підходить для будь-якої прогулянки. Вирушайте з нами на унікальну втечу на хвилях."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/2-2.jpg?v=2024-06-12T07:35:51.352Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ознайомтеся з нашими пропозиціями
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					У OceanQuest Yachting ми віримо, що кожна морська подорож має бути унікальною, як і наші гості. Наш повний перелік послуг розроблений для задоволення будь-яких ваших потреб, забезпечуючи бездоганний і збагачувальний досвід плавання. Від персоналізованих маршрутів до першокласних зручностей на борту, ми гарантуємо подорож, яка стане не просто поїздкою, а запам’ятається надовго.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/6668b0988e2e8e00217cc745/images/1-2.jpg?v=2024-06-12T07:35:51.324Z) bottom/cover no-repeat scroll padding-box"
				lg-min-height="400px"
				lg-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 75%/cover no-repeat scroll padding-box"
				md-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 100%/cover no-repeat scroll padding-box"
				sm-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 50% 100%/cover no-repeat scroll padding-box"
			/>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Основні послуги
			</Text>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Наш флот є основою наших пропозицій, що включає різноманітний асортимент суден, які поєднують класичний шарм із сучасним комфортом. Кожна яхта оснащена найновішими навігаційними технологіями та розкішно оснащена, щоб забезпечити комфортну та безпечну подорож.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Індивідуальні маршрути: Налаштуйте свою подорож, включивши в неї улюблені прибережні напрямки, або дозвольте нам здивувати вас прихованими дорогоцінними каменями.{"\n"}
							<br />
							<br />
							{" "}Оденні екскурсії: Ідеально підходить для тих, хто хоче відпочити на один день.{"\n"}
							<br />
							<br />
							{" "}Круїзи на заході сонця: Ідеально підходить для романтичних вечорів або особливих свят.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Кертінг на борту: насолодіться стравами для гурманів із меню, складеним відповідно до ваших уподобань.{"\n"}
							<br />
							<br />
							{" "}Місцеві делікатеси: Скуштуйте смаки місцевої кухні, приготовані майстерними кухарями.
							<br />
							<br />
							{"\n "}Особливі побажання: від дієтичних обмежень до святкових тортів, ми впораємося з усім.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Проведення подій: Перетворіть звичайну поїздку на вражаючу подію, будь то день народження, ювілей чи корпоративна прогулянка.{"\n"}
							<br />
							<br />
							{" "}Декор і налаштування:  Індивідуальні прикраси відповідно до події.{"\n"}
							<br />
							<br />
							{" "}Варіанти розваг:  від живої музики до професійної фотозйомки, покращте вашу подію.
						</Text>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Цей огляд лише нагадує про те, що ми пропонуємо в OceanQuest Yachting. Зверніться до нашої команди, щоб отримати повний спектр наших послуг і персоналізувати свою наступну морську пригоду. Відкрийте для себе весь спектр наших пропозицій, зв’язавшись з нами, і дозвольте нам створити для вас ідеальну морську втечу.
			</Text>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});